import { Field } from 'Util/Query';

/**
 * @namespace TrouperPwa/Query/SubCategoryProducts/Query */

export class SubCategoryProductsQuery {
    categoryList(id: any) {
        // Create the category list field
        return new Field('categoryList')
            .addArgument('filters', 'CategoryFilterInput', { ids: { eq: id } })
            .addFieldList([
                'id',
                'name',
                'product_count',
                'level',
                new Field('children').addFieldList([
                    'name',
                    'image',
                    'short_desc',
                    'promo_msg',
                    'url_path',
                    'url_key',
                    'product_count',
                    'level',
                ]),
            ]);
    }
}

export default SubCategoryProductsQuery;
