import { Field } from 'Util/Query';

/**
 * @namespace TrouperPwa/Query/GetBanners/Query
 */

export class GetBannersQuery {
    getBanners(sku: string | null, category_id: number, position: string) {
        // Create the getBanners field with arguments
        return new Field('getBanners')
            .addArgument('sku', 'String', sku)
            .addArgument('category_id', 'Int', category_id)
            .addArgument('position', 'String', position)
            .addFieldList([
                'id',
                'rule_name',
                'is_active',
                'from_date',
                'to_date',
                'stores',
                'cust_groups',
                'banner_position',
                'banner_img',
                'banner_link',
                'banner_title',
                'cms_block',
                'banner_type',
                'after_n_product_row',
            ]);
    }
}

export default GetBannersQuery;
