/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa-theme
 */

export enum NavigationTabsMap {
    HOME_TAB = 'HOME_TAB',
    MENU_TAB = 'MENU_TAB',
    ACCOUNT_TAB = 'ACCOUNT_TAB',
    CART_TAB = 'CART_TAB',
    CHECKOUT_TAB = 'CHECKOUT_TAB',
    SERVICES = 'SERVICES',
    MORE_PETS = 'MORE_PETS',
    CAT = 'CAT',
    DOG = 'DOG',
}
