/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable */

import { PureComponent } from 'react';

// import TextPlaceholder from 'Component/TextPlaceholder';
import { ReactElement } from 'Type/Common.type';

import { CategoryItemsCountComponentProps } from './CategoryItemsCount.type';

/** @namespace TrouperPwa/Component/CategoryItemsCount/Component */
export class CategoryItemsCountComponent extends PureComponent<CategoryItemsCountComponentProps> {
    static defaultProps: Partial<CategoryItemsCountComponentProps> = {
        isMatchingListFilter: false,
        pageSize: 48,
    };

    getPageNumber(): number {
        const params = new URLSearchParams(window.location.search);
        const page = params.get('page');

        return page ? parseInt(page, 10) : 1; // Default to page 1 if not specified
    }

    render(): ReactElement {
        const {
            totalItems,
            pageSize = 48,
        } = this.props;

        const currentPage = this.getPageNumber();

        // Calculate the start and end index for the current page
        const startIndex = (currentPage - 1) * pageSize + 1;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems);

        return totalItems !== 0 && <p block="CategoryPage" elem="ItemsCount">
            <span>
                {startIndex}
                {' '}
                -
                {' '}
                {endIndex}
                {' '}
                of
            </span>
            {' '}
            {totalItems}
            {' '}
            results
        </p>



    }
}

export default CategoryItemsCountComponent;
