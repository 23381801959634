/* eslint-disable react/forbid-dom-props */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable */

import ResetAttributes from '@scandipwa/scandipwa/src/component/ResetAttributes';
import ResetButton from '@scandipwa/scandipwa/src/component/ResetButton';
import {
    createRef, PureComponent, RefObject, Suspense,
} from 'react';
import CategoryDetails from 'Component/CategoryDetails';
import CategoryItemsCount from 'Component/CategoryItemsCount';
import CategoryProductList from 'Component/CategoryProductList';
import { CategorySortField } from 'Component/CategorySort/CategorySort.type';
import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { ReactElement } from 'Type/Common.type';
import { isCrawler, isSSR } from 'Util/Browser';
import BrowserDatabase from 'Util/BrowserDatabase';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';
import noSearchImage from 'src/style/icons/no-search-result-page.png';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CmsBlock from '../../component/CmsBlock/CmsBlock.container';
import filter_icon from '../../style/icons/filter_icon.svg';
import { customImageSliderSettings, CustomImageSliderLoopSettings } from 'Route/CmsPage/CmsPage.constants';

import './CategoryPage.style';
import { CategoryDisplayMode, CategoryPageLayout,
  LAYOUT_KEY } from 'SourceRoute/CategoryPage/CategoryPage.config';
import GetBannersQuery from 'Query/GetBanners.query';
import { fetchQuery } from 'SourceUtil/Request/Query';
import { CategoryPageComponentProps } from 'SourceRoute/CategoryPage/CategoryPage.type';
import SlickSliderComponent from 'Component/SlickSlider/SlickSlider.component';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from '@scandipwa/scandipwa/src/util/Store';

export const CategoryFilterOverlay = lowPriorityLazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "overlays-category" */ 'Component/CategoryFilterOverlay'
    ),
);
export const FilterIcon = lowPriorityLazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "category-misc" */ 'Component/FilterIcon'
    ),
);
export const GridIcon = lowPriorityLazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "category-misc" */ 'Component/GridIcon'
    ),
);
export const ListIcon = lowPriorityLazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "category-misc" */ 'Component/ListIcon'
    ),
);
export const CategorySort = lowPriorityLazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "category-misc" */ 'Component/CategorySort'
    ),
);


interface SearchProps {
  search: string;
  totalItems: number;
  searchResults?: string;
}

export interface CategoryPageComponentState {
  activeLayoutType: any;
  bannersData?: any;
  toggleCustomFilter?: () => void;
  onSeeResultsClick?: () => void;
}

/** @namespace TrouperPwa/Route/CategoryPage/Component */
// export class CategoryPageComponent<
//   P extends CategoryPageComponentProps = CategoryPageComponentProps,
//   S extends CategoryPageComponentState = CategoryPageComponentState,
// > extends PureComponent<P, S> {
  export class CategoryPageComponent extends PureComponent<
  CategoryPageComponentProps,
  CategoryPageComponentState
> {
    static defaultProps: Partial<CategoryPageComponentProps> = {
        isContentFiltered: true,
        isMatchingListFilter: false,
        isCurrentCategoryLoaded: false,
        isMatchingInfoFilter: false,
        totalPages: 1,
        defaultPlpType: undefined,
        plpTypes: [],
        search: '',
        appliedFiltersCount: 0,
        selectedLayoutType: undefined,
    };

     // Explicitly type the state
    state: CategoryPageComponentState = {
        activeLayoutType: undefined,
        bannersData: undefined,
    };

    static getDerivedStateFromProps(
        props: CategoryPageComponentProps,
    ): Partial<CategoryPageComponentState> {
        const { isMobile, defaultPlpType, selectedLayoutType } = props;

        /*
     * Use stored plpType from the BrowserDatabase
     * if there is one
     */
        const storedPlpType = BrowserDatabase.getItem<CategoryPageLayout>(LAYOUT_KEY)
      || selectedLayoutType;

        if (storedPlpType) {
            const activeLayoutType = isMobile
                ? CategoryPageLayout.GRID
                : storedPlpType || defaultPlpType;

            return { activeLayoutType };
        }

        const activeLayoutType = isMobile
            ? CategoryPageLayout.GRID
            : selectedLayoutType || defaultPlpType;

        return { activeLayoutType };
    }

    categoryPageRef: RefObject<HTMLDivElement> = createRef();


    initializeCarousel = () => {
      const timeoutNumber = 500;
      const mobileBreakpoint = window.matchMedia('(max-width: 768px)').matches;
      setTimeout(() => {
          // Select all custom image slider elements
          const customImageSliders = document.querySelectorAll('.custom-image-slider');

          customImageSliders.forEach((CustomImageSlider) => {
              /* eslint-disable max-len */
              const CustomImageSliderItems = CustomImageSlider && CustomImageSlider.querySelectorAll('.custom-image-slider figure:not(.slider-bg-image)');

              if (CustomImageSliderItems.length > 0) {
                  // eslint-disable-next-line react/no-deprecated
                  ReactDOM.render(
              <Provider store={ store }>
                  <SlickSliderComponent
                    { ...customImageSliderSettings }// eslint-disable-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                    categoryItems={ Array.from(CustomImageSliderItems) } // Convert NodeList to Array
                  />
              </Provider>,
              CustomImageSlider, // Render the component in the current slider element
                  );
              }
          });

          // Select all custom image slider loop elements
          const customImageSliderLoops = document.querySelectorAll('.custom-image-slider-loop');
          customImageSliderLoops.forEach((CustomImageSliderLoop) => {
              /* eslint-disable max-len */
              const CustomImageSliderLoopItems = CustomImageSliderLoop && CustomImageSliderLoop.querySelectorAll('.custom-image-slider-loop figure:not(.slider-bg-image)');

              if (CustomImageSliderLoopItems.length > 0) {
                  // eslint-disable-next-line react/no-deprecated
                  ReactDOM.render(
                      <Provider store={ store }>
                          <SlickSliderComponent
                            { ...CustomImageSliderLoopSettings }// eslint-disable-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                            categoryItems={ Array.from(CustomImageSliderLoopItems) } // Convert NodeList to Array
                          />
                      </Provider>,
                      CustomImageSliderLoop, // Render the component in the current loop
                  );
              }
          });
      }, timeoutNumber);
  };

    displayProducts() {
        const { displayMode } = this.props;

        return (
            displayMode === null
      || displayMode === CategoryDisplayMode.PRODUCTS
      || displayMode === CategoryDisplayMode.BOTH
        );
    }

    displayCmsBlock(): boolean {
        const { category: { display_mode } = {} } = this.props;

        return (
            display_mode === CategoryDisplayMode.CMS_BLOCK
      || display_mode === CategoryDisplayMode.BOTH
        );
    }


    async fetchBanners(categoryId: any): Promise<void> {
      try {
          const getBannersQuery:any = new GetBannersQuery().getBanners(null, categoryId, "5"); 
          const bannersResponse: any = await fetchQuery(getBannersQuery);

          const banners:any = bannersResponse?.getBanners;
          if (banners) {
              this.setState({ bannersData: banners }, () => {
              });
              
          }
      } catch (error) {
          console.error('Error fetching banners data:', error);
      }
  }


renderBannerContent(banner: any): ReactElement {
  const blockContent = banner.cms_block || banner.banner_img;
  const isCmsBlock = !!banner.cms_block;
  const cmsBannerBlockIdentifier = banner.cms_block;

  return (
    <div className="banner-container">
      {isCmsBlock ? (
        <CmsBlock identifier={cmsBannerBlockIdentifier} />
      ) : (
        <img className="banner-img" src={blockContent} alt="Banner" />
      )}
    </div>
  );
}

// Function to render banners
renderBanners(): ReactElement | null {
  const { bannersData } = this.state;

  // Ensure there's data to render
  if (!bannersData || bannersData.length === 0) {
    return null;
  }

  return (
    <div className="banners-section">
      {bannersData.map((banner: any) => (
        <div key={banner.id}>
          {this.renderBannerContent(banner)}
        </div>
      ))}
    </div>
  );
}


renderUspSsectionContent(): ReactElement {

  const uspSsectionBlockIdentifier = 'usp-section';
  if (uspSsectionBlockIdentifier) {
    return <CmsBlock identifier={uspSsectionBlockIdentifier} />
  }
}

componentDidUpdate(prevProps: Readonly<CategoryPageComponentProps & SearchProps>): void {
  const { category, totalItems, search, selectedFilters, isLoading } = this.props;

  setTimeout(() => {
    const currentCategoryId: any = category?.id ?? null;
    const previousCategoryId: any = prevProps.category?.id ?? null;

    if (currentCategoryId !== previousCategoryId && currentCategoryId) {
      this.fetchBanners(currentCategoryId);
    }

    if (
      search &&
      (search !== prevProps.search || totalItems !== prevProps.totalItems) &&
      totalItems > 0
    ) {
      if (!isLoading) {
        this.addSearchTerm(search);
      }
    }
  }, 2000);
}
    
    renderCategoryDetails(): ReactElement {
        const { category, isCurrentCategoryLoaded } = this.props;
        const { isPrefetchValueUsed } = window;
        
        return (
          <>
          <CategoryDetails
            category={ category }
            isCurrentCategoryLoaded={ isPrefetchValueUsed || isCurrentCategoryLoaded }
          />
          </>
        );
    }

    renderFiltersCount(): ReactElement {
        const { appliedFiltersCount } = this.props;

        if (!appliedFiltersCount) {
            return null;
        }

        return (
      <span block="CategoryPage" elem="Subheading">
        { ` ${appliedFiltersCount}` }
      </span>
        );
    }

    renderFilterButton(): ReactElement {
        const {
            isContentFiltered,
            totalPages,
            category: { is_anchor },
            isSearchPage,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            onFilterButtonClick,
        } = this.props;

        if (!isMatchingInfoFilter) {
            return this.renderFilterButtonPlaceholder();
        }

        if (
            (!isContentFiltered && totalPages === 0)
      || (!is_anchor && !isSearchPage)
      || !isCurrentCategoryLoaded
        ) {
            return null;
        }

        return (
      <button block="CategoryPage" elem="Filter" onClick={ onFilterButtonClick }>
        <div block="CategoryPage" elem="MobileFilter">
        <span>{ __('All Filter') }</span>
        { this.renderFiltersCount() }
        </div>
        <Suspense
          fallback={ <div block="CategoryPage" elem="FilterPlaceholder" /> }
        >
          <img src={filter_icon} alt="filter_icon" />
        </Suspense>
      </button>
        );
    }

    renderPlaceholder(block: string): ReactElement {
        return (
            <>
        <div block={ block } elem="SwatchList">
          <div block={ block } elem="Placeholder" />
          <div block={ block } elem="Placeholder" />
          <div block={ block } elem="Placeholder" />
        </div>
        <Loader isLoading />
            </>
        );
    }

    renderFilterPlaceholder(): ReactElement {
        return (
      <div block="CategoryPage" elem="PlaceholderWrapper">
        <div block="CategoryPage" elem="PlaceholderContainer">
          <h3 block="CategoryPage" elem="PlaceholderHeading">
            { __('Shopping Options') }
          </h3>
          <div block="CategoryPage" elem="PlaceholderList">
            <div block="CategoryPage" elem="PlaceholderListItem" />
            <div block="CategoryPage" elem="PlaceholderListItem" />
            <div block="CategoryPage" elem="PlaceholderListItem" />
          </div>
          <Loader isLoading />
        </div>
      </div>
        );
    }

    renderFilterButtonPlaceholder(): ReactElement {
        return (
      <p block="CategoryPage" elem="FilterButtonPlaceholder">
        <TextPlaceholder length={ TextPlaceHolderLength.SHORT } />
      </p>
        );
    }

    renderHeading(): ReactElement {
        const { isContentFiltered } = this.props;

        return (
      <h3
        block="CategoryFilterOverlay"
        elem="Heading"
        mods={ { isContentFiltered } }
      >
        { __('Shopping Options') }
      </h3>
        );
    }

    renderResetAttributes(): ReactElement {
        const {
            filters,
            selectedFilters,
            isMatchingInfoFilter,
            isSearchPage,
            mobileBackdrop,
            // @ts-ignore
            toggleCustomFilter,
        } = this.props;

        return (
      <ResetAttributes
        customFiltersValues={ selectedFilters }
        availableFilters={ filters }
        toggleCustomFilter={ toggleCustomFilter }
      />
        );
    }

    renderResetButton(): ReactElement {
      // @ts-ignore
        const { onSeeResultsClick } = this.props;

        return (
      <div>
        <ResetButton
          onClick={ onSeeResultsClick }
          mix={ { block: 'CategoryFilterOverlay', elem: 'ResetButton' } }
          categoryPageRef={ this.categoryPageRef }
        />
      </div>
        );
    }

    renderDefaultFilters(): ReactElement {
      return (
        <>
          <div block="CategoryFilterOverlay" elem="ResetSection" style={{ display: 'flex', margin: '20px 0' }}>
            {this.renderResetAttributes()}
            {' '}
            {this.renderResetButton()}

          </div>
        </>
      );
    }

    renderFilterOverlay(): ReactElement {
        const {
            filters,
            selectedFilters,
            isMatchingInfoFilter,
            isSearchPage,
            mobileBackdrop,
        } = this.props;

        const {
            category: { is_anchor },
        } = this.props;

        if (!this.displayProducts()) {
            return null;
        }

        return (
      <Suspense fallback={ this.renderFilterPlaceholder() || null }>
        <CategoryFilterOverlay
          availableFilters={ filters }
          customFiltersValues={ selectedFilters }
          isMatchingInfoFilter={ isMatchingInfoFilter }
          isCategoryAnchor={ !!is_anchor }
          isSearchPage={ isSearchPage }
          renderPlaceholder={ this.renderPlaceholder }
          categoryPageRef={ this.categoryPageRef }
          mobileBackdrop={ mobileBackdrop }
        />
      </Suspense>
        );
    }

    renderCategorySort(): ReactElement {
        const {
            sortFields,
            selectedSort,
            onSortChange,
            isMatchingInfoFilter,
            isCurrentCategoryLoaded,
            isMobile,
        } = this.props;

        const { options = [] } = sortFields;
        const updatedSortFields: CategorySortField[] = options.map(
            ({ value: id, label }) => ({ id, label }),
        );
        const { sortDirection, sortKey } = selectedSort;

        if (isMobile && !isMatchingInfoFilter) {
            return this.renderFilterButtonPlaceholder();
        }

        return (
      <Suspense
        fallback={ <div block="CategoryPage" elem="CategorySortPlaceholder" /> }
      >
        <CategorySort
          isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
          isMatchingInfoFilter={ isMatchingInfoFilter }
          onSortChange={ onSortChange }
          sortFields={ updatedSortFields }
          sortKey={ sortKey }
          sortDirection={ sortDirection }
        />
      </Suspense>
        );
    }

    renderLayoutButton(type: CategoryPageLayout): ReactElement {
        const { onGridButtonClick, onListButtonClick } = this.props;

        const { activeLayoutType } = this.state;

        switch (type) {
        case CategoryPageLayout.GRID:
            return (
          <button
            key={ type }
            onClick={ onGridButtonClick }
            mix={ {
                block: CategoryPageLayout.GRID,
                mods: { isActive: activeLayoutType === CategoryPageLayout.GRID },
            } }
            aria-label="grid"
          >
            <Suspense fallback={ null }>
              <GridIcon
                isActive={ activeLayoutType === CategoryPageLayout.GRID }
              />
            </Suspense>
          </button>
            );
        case CategoryPageLayout.LIST:
            return (
          <button
            key={ type }
            onClick={ onListButtonClick }
            mix={ {
                block: CategoryPageLayout.LIST,
                mods: { isActive: activeLayoutType === CategoryPageLayout.LIST },
            } }
            aria-label="list"
          >
            <Suspense fallback={ null }>
              <ListIcon
                isActive={ activeLayoutType === CategoryPageLayout.LIST }
              />
            </Suspense>
          </button>
            );
        default:
            return false;
        }
    }

    renderLayoutButtons(): ReactElement {
        const { plpTypes } = this.props;

        /*
     * So far there is only two types of
     * the Storefront list modes
     */
        if (plpTypes.length !== 2) {
            return null;
        }

        return (
      <div block="CategoryPage" elem="LayoutButtons">
        { plpTypes.map(this.renderLayoutButton.bind(this)) }
      </div>
        );
    }

    renderItemsCount(isVisibleOnMobile = false): ReactElement {
        const { isMatchingListFilter, isMobile } = this.props;

        if ((isVisibleOnMobile && !isMobile) || (!isVisibleOnMobile && isMobile)) {
            return null;
        }

        return <CategoryItemsCount isMatchingListFilter={ isMatchingListFilter } />;
    }

    renderCategoryProductList(): ReactElement {
        const {
            filter,
            search,
            selectedSort,
            selectedFilters,
            isMatchingListFilter,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            productListLoaderRef,
            totalItems
        } = this.props;

        const { activeLayoutType } = this.state;

        if (!this.displayProducts()) {
            setLoadedFlag();

            return null;
        }

        return (
      <div
        block="CategoryPage"
        elem="ProductListWrapper"
        mods={ { isPrerendered: isSSR() || isCrawler(),noProductListWrapper:totalItems === 0 } }
      >
        { this.renderItemsCount(true) }
        <CategoryProductList
          filter={ filter }
          search={ search }
          sort={ selectedSort }
          selectedFilters={ selectedFilters }
          isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
          isMatchingListFilter={ isMatchingListFilter }
          isMatchingInfoFilter={ isMatchingInfoFilter }
          layout={ activeLayoutType || CategoryPageLayout.GRID }
          productListLoaderRef={ productListLoaderRef }
        />
      </div>
        );
    }

    renderCmsBlock(): ReactElement {
        const {
            category: { cms_block },
            isCurrentCategoryLoaded,
        } = this.props;

        if (!cms_block || !this.displayCmsBlock() || !isCurrentCategoryLoaded) {
            return null;
        }

        const { content, disabled } = cms_block;

        if (disabled) {
            return null;
        }

        return (
      <div
        block="CategoryPage"
        elem="CMS"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={ { __html: content } }
      />
        );
    }

    renderMiscellaneous(): ReactElement {
      const {
        isMobile,
        search,
        totalItems
    } = this.props;

        return (
          <> { (totalItems > 0 && search.length) ?
          <div block="CategoryPage" elem="SearchTopBanner">
           { this.renderwebSearchTopBannerBlock() }
          </div> : ""}
      <aside block="CategoryPage" elem="Miscellaneous">
        { this.renderItemsCount() }
        <div block="CategoryPage" elem="MiscellaneousLayoutWrapper">
        <div
            block="CategoryPage"
            elem="LayoutWrapper"
            mods={ { isPrerendered: isSSR() || isCrawler() } }
          >
            { this.renderFilterButton() }
          </div>

          <div
            block="CategoryPage"
            elem="LayoutWrapper"
            mods={ { isPrerendered: isSSR() || isCrawler() } }
          >
            {!isMobile && <span block="CategoryPage"
            elem="Sortby">Sort by</span> }
            { this.renderCategorySort() }
          </div>
          
        </div>
      </aside>
      </>
        );
    }

  noSearchImageFound(): ReactElement {
    return (
      <div>
        <img src={noSearchImage} alt="noSearchImage" />
      </div>
    )
  }

  addSearchTerm(term: string): void {

    const { totalItems, search } = this.props;

    if (search && totalItems > 0) {
    const recentSearches = localStorage.getItem('recentSearches');
    const searchArray = recentSearches ? JSON.parse(recentSearches) : [];
    const newSearch = {
        id: Date.now(),
        term,
    };
    const filteredSearchArray = searchArray.filter(
        (search: { id: number; term: string }) => search.term !== term
    );
    filteredSearchArray.unshift(newSearch);

    if (filteredSearchArray.length > 5) {
        filteredSearchArray.pop();
    }
    localStorage.setItem('recentSearches', JSON.stringify(filteredSearchArray));
}
}


renderwebSearchTopBannerBlock(): ReactElement | null {
  const webSearchTopBannerBlockIdentifier = 'web-search-top-banner';

  if (webSearchTopBannerBlockIdentifier) {
      return <CmsBlock identifier={ webSearchTopBannerBlockIdentifier } />;
  }

  return null;
}

  renderContent(): ReactElement {
    const { totalItems, isMobile, search } = this.props;
    const { bannersData } = this.state;

    if (isMobile) {
        return (
            <>
                { totalItems > 0 && this.renderFilterOverlay() }
                { totalItems > 0 && this.renderCategoryDetails() }
                { totalItems > 0 && this.renderCmsBlock() }
                { totalItems > 0 && this.renderMiscellaneous() }
                { this.renderCategoryProductList() }
                { this.initializeCarousel() } 
            </>
        );
    }

    return (
        <>
            {totalItems > 0 && this.renderMiscellaneous()}
            {totalItems > 0 && this.renderFilterOverlay()}
            {totalItems > 0 && this.renderDefaultFilters()}
            {this.renderCategoryProductList()}
            { this.initializeCarousel() } 
        </>
    );
}


render(): ReactElement {
  const hideProducts = !this.displayProducts();
  const { totalItems, mobileBackdrop, isLoading, isMobile, search } = this.props;

  return (
      <main block="CategoryPage" mods={ { noResults: totalItems === 0 } } ref={ this.categoryPageRef }>
 {!isMobile && 
           <ContentWrapper>
            { totalItems > 0 && this.renderCategoryDetails() }
            { this.renderCmsBlock() }
            </ContentWrapper>
          }
                    <ContentWrapper
            wrapperMix={ {
                block: 'CategoryPage',
                elem: 'Wrapper',
                mods: { hideProducts,noSearch: totalItems === 0 && !isLoading },
            } }
            label="Category page"
          >
              <div
                block="CategoryPage"
                elem="MobileBackdrop"
                ref={ mobileBackdrop }
              />
              <div
                block="CategoryPage"
                elem="Loader"
                mods={ {} }
              />
              { this.renderContent() }
          </ContentWrapper>
          <ContentWrapper>
            {!search && 
            <>
              <div style={{margin:"0px 0"}}>
            {this.renderBanners()}
              </div>
              </>
            }
          
          </ContentWrapper> 
      </main>
  );
}
}


export default CategoryPageComponent;
